<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="row mb-3" *ngIf="config?.icon || config?.trailing_icon || config?.text">
      <ng-container *ngIf="config?.icon">
        <div
          class="me-auto"
          [ngClass]="
            config?.icon && config?.trailing_icon
              ? 'col-6'
              : 'col-3 pe-0 order-first'
          "
        >
          <label class="text-white w-100" style="text-align: left">
            {{ config?.icon && !config?.trailing_icon ? 'Icon' : 'Leading Icon'}}
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <button
              type="button"
              class="form-control input-lg offcanvas-primary border-0 d-flex align-items-center justify-content-center"
              (click)="openSelector(selectorIcons, 0)"
            >
              <i
                class="fs-4"
                [class]="
                  icon?.type == 'mir'
                    ? 'material-icons-outlined'
                    : 'material-icons'
                "
              >
                {{ icon?.id }}
              </i>
            </button>
            <button
              type="button"
              class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
              (click)="openSelector(selectorIcons, 0)"
            >
              <i class="mdi mdi-chevron-down"></i>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="config?.trailing_icon">
        <div
          [ngClass]="
            config?.icon && config?.trailing_icon
              ? 'col-6'
              : 'col-3 ps-0 order-last'
          "
        >
          <label class="text-white w-100" style="text-align: left">
            {{ !config?.icon && config?.trailing_icon ? 'Icon' : 'Trailing Icon'}}
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <button
              type="button"
              class="form-control input-lg offcanvas-primary border-0 d-flex align-items-center justify-content-center"
              (click)="openSelector(selectorIcons, 1)"
            >
              <i
                class="fs-4"
                [class]="
                  trailing_icon?.type == 'mir'
                    ? 'material-icons-outlined'
                    : 'material-icons'
                "
              >
                {{ trailing_icon?.id }}
              </i>
            </button>
            <button
              type="button"
              class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
              (click)="openSelector(selectorIcons, 1)"
            >
              <i class="mdi mdi-chevron-down"></i>
            </button>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="config?.text">
        <div
          [ngClass]="
            config?.icon && config?.trailing_icon
              ? 'col-12 w-100 order-first mb-3'
              : config?.icon || config?.trailing_icon
              ? 'col-9'
              : 'col-12 w-100 order-first'
          "
        >
          <label
            for="text-color"
            class="text-white w-100"
            style="text-align: left"
          >
            {{config?.name}}
          </label>
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <form class="needs-validation w-100" [formGroup]="labelform">
              <input
                type="text"
                id="lablecolor"
                formControlName="lableControl"
                class="form-control input-lg offcanvas-primary border-0"
                [value]="button_text"
                (change)="buttonLabel()"
                placeholder="Lable"
                [ngClass]="{ 'is-invalid': f.lableControl.errors }"
              />
              <div *ngIf="f.lableControl.errors" class="invalid-feedback">
                <span *ngIf="f.lableControl.errors.required">
                  Field is required
                </span>
                <span *ngIf="f.lableControl.errors.maxlength">
                  Actual length is
                  {{ f.lableControl.errors.maxlength.actualLength }} required not
                  more than
                  {{ f.lableControl.errors.maxlength.requiredLength }}
                  chars.
                </span>
              </div>
            </form>
          </div>
        </div>
      </ng-container>

    </div>
    <div class="row mb-3" *ngIf="config?.font">
      <div class="col-12 w-100 d-flex flex-column align-items-start">
        <label class="text-white w-100" style="text-align: left">
          Font Size
        </label>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio1"
            autocomplete="off"
            [checked]="fontSize === 'md'"
            (change)="updateFontSize('md')"
          />
          <label class="btn btn-secondary" for="btnradio1">Default</label>

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio2"
            autocomplete="off"
            [checked]="fontSize === 'lg'"
            (change)="updateFontSize('lg')"
          />
          <label class="btn btn-secondary" for="btnradio2">Large</label>

          <input
            type="radio"
            class="btn-check"
            name="btnradio"
            id="btnradio3"
            autocomplete="off"
            [checked]="fontSize === 'sm'"
            (change)="updateFontSize('sm')"
          />
          <label class="btn btn-secondary" for="btnradio3">Small</label>
        </div>
      </div>
    </div>
    <div class="row mb-3" *ngIf="config?.align">
      <div class="col-12 w-100 d-flex flex-column align-items-start">
        <label class="text-white w-100" style="text-align: left">
          Text Alignment
        </label>
        <div
          class="btn-group"
          role="group"
          aria-label="Basic radio toggle button group"
        >
          <input
            type="radio"
            class="btn-check"
            name="alignment"
            id="alignment1"
            autocomplete="off"
            [checked]="textAlign === 'left'"
            (change)="updateLapleAlignmet('left')"
          />
          <label class="btn btn-secondary" for="alignment1">Left</label>

          <input
            type="radio"
            class="btn-check"
            name="alignment"
            id="alignment2"
            autocomplete="off"
            [checked]="textAlign === 'center'"
            (change)="updateLapleAlignmet('center')"
          />
          <label class="btn btn-secondary" for="alignment2">Center</label>

          <input
            type="radio"
            class="btn-check"
            name="alignment"
            id="alignment3"
            autocomplete="off"
            [checked]="textAlign === 'right'"
            (change)="updateLapleAlignmet('right')"
          />
          <label class="btn btn-secondary" for="alignment3">Right</label>
        </div>
      </div>
    </div>
    <div *ngIf="config?.color" class="row align-items-center" [class.mt-4]="config?.text">
      <label for="bg-color" class="text-white col-9" style="text-align: left">
        Custom {{config?.name}} Color
      </label>
      <div class="col-3 text-start p-0" style="margin-bottom: 0.5rem;" *ngIf="!enableCustomColor">
        <a class="link-primary curser-pointer" (click)="toggle(true)">
          Set
        </a>
      </div>
      <ng-container *ngIf="enableCustomColor">
        <div class="col-9">
          <div
            class="input-group offcanvas-border-primary border rounded-2"
            title="Using format option"
          >
            <input
              type="text"
              id="bg-color"
              class="form-control input-lg offcanvas-primary border-0"
              (change)="colorChange($event, true)"
              [value]="labelColor"
              [readOnly]="!enableCustomColor"
            />
            <span class="input-group-text p-1 border-0 offcanvas-primary">
              <span
                class="input-group-text colorpicker-input-addon h-100"
                [style.background]="labelColor"
                [cpOutputFormat]="'hex'"
                [(colorPicker)]="labelColor"
                [cpPosition]="'bottom-left'"
                (colorPickerChange)="colorChange($event, false)"
                [cpDisabled]="!enableCustomColor"
              >
              </span>
            </span>
          </div>
        </div>
        <div class="col-3 text-start p-0">
          <a
            class="link-primary curser-pointer"
            (click)="toggle(false)"
          >
            Remove
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #selectorIcons let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="selector">
      {{ iconType ? "Choose Trailing Icon" : "Choose Leading Icon" }}
    </h5>
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="modal"
      aria-label="Close"
      (click)="modalRef?.hide()"
    ></button>
  </div>
  <div class="modal-body">
    <app-icons-selector
      (selectedIcon)="iconChange($event)"
    ></app-icons-selector>
  </div>
</ng-template>
