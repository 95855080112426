<div class="card m-0 bg-transparent">
  <div class="card-body">
    <div class="card-header bg-transparent p-0 mb-3">
      <h6 class="text-white text-start">
        {{ "GENERALS.OPTIONS" | translate }}
      </h6>
    </div>
    <div class="row m-0">
      <div cdkDropList class="col-12 p-0" (cdkDropListDropped)="drop($event)">
        <ng-container *ngFor="let opt of button_option">
          <div
            class="card offcanvas-primary mb-2"
            cdkDrag
            cdkDragLockAxis="y"
            [cdkDragDisabled]="selecteSittings"
            *ngIf="
              !selecteSittings ||
              (selecteSittings && selecteSittings.id !== opt.id)
            "
            style="cursor: move"
          >
            <div class="row m-0 no-gutters align-items-center">
              <div class="col-md-12 p-0">
                <div class="row m-0">
                  <div
                    class="col-2 d-flex align-items-center justify-content-center"
                  >
                    <i
                      *ngIf="config?.icon && (opt?.icon || opt?.icon_ios)"
                      class="float-none w-auto m-0 px-1"
                      [class]="
                        getIcon(opt).type == 'mir'
                          ? 'material-icons-outlined'
                          : 'material-icons'
                      "
                    >
                      {{ getIcon(opt).id }}
                    </i>
                    <img
                      *ngIf="opt.image && config?.image"
                      [src]="opt.image"
                      alt="Card image"
                      class="card-img img-fluid object-fit-cover rounded-circle"
                      style="max-height: 60px; max-width: 100%"
                    />
                  </div>
                  <div class="col-10 p-0">
                    <div
                      class="card-body card-btn-toggle position-relative ps-0"
                    >
                      <p class="card-text text-start text-white m-0 ps-3">
                        {{ opt.label ? opt.label : "New Item" }}
                      </p>
                      <button
                        class="btn btn-primary text-white p-0 position-absolute"
                        style="
                          width: 32px;
                          height: 32px;
                          top: 50%;
                          right: 40px;
                          transform: translateY(-50%);
                        "
                        (click)="openSettings(opt)"
                      >
                        <i class="fas fa-cog font-size-14"></i>
                      </button>
                      <button
                        class="btn btn-danger text-white p-0 position-absolute"
                        style="
                          width: 32px;
                          height: 32px;
                          top: 50%;
                          right: 5px;
                          transform: translateY(-50%);
                        "
                        (click)="deleteImage(opt.id)"
                      >
                        <i class="mdi mdi-delete font-size-14"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card bg-transparent bg-opacity-50 mb-2"
            cdkDrag
            *ngIf="selecteSittings && selecteSittings.id === opt.id"
            style="border: 1px solid"
          >
            <div class="card-body">
              <form [formGroup]="optionform" (ngSubmit)="saveChanges()">
                <div class="mb-3 text-start">
                  <label
                    for="text-color"
                    class="text-white w-100"
                    style="text-align: left"
                  >
                    {{ "GENERALS.OPTIONID" | translate }}
                  </label>
                  <input
                    readonly
                    type="text"
                    id="text-color"
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="id"
                  />
                </div>
                <div class="mb-3">
                  <label
                    for="title"
                    class="text-white w-100"
                    style="text-align: left"
                  >
                    {{ "GENERALS.LABEL" | translate }}
                  </label>
                  <input
                    type="text"
                    id="optTitle"
                    #optTitle
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="label"
                  />
                </div>
                <div
                  class="row d-flex justify-content-between align-items-center mb-3"
                  *ngIf="config?.icon || config?.trailing_icon"
                >
                  <div class="col-6" *ngIf="config?.icon">
                    <label class="text-white w-100" style="text-align: left">
                      Leading Icon
                    </label>
                    <div
                      class="input-group offcanvas-border-primary border rounded-2"
                      title="Using format option"
                    >
                      <button
                        type="button"
                        class="form-control input-lg offcanvas-primary border-0 d-flex align-items-center justify-content-center"
                        (click)="openSelector(selectorOption, 0)"
                      >
                        <i
                          class="fs-4"
                          [class]="
                            icon?.type == 'mir'
                              ? 'material-icons-outlined'
                              : 'material-icons'
                          "
                        >
                          {{ icon?.id }}
                        </i>
                      </button>
                      <button
                        type="button"
                        class="btn offcanvas-primary dropdown-toggle dropdown-toggle-split border-0"
                        (click)="openSelector(selectorOption, 0)"
                      >
                        <i class="mdi mdi-chevron-down"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="mb-3" *ngIf="config?.image">
                  <app-image-uploader
                    [data]="imageUploaderConfig"
                    [removePadding]="true"
                    (images)="saveImages($event)"
                  ></app-image-uploader>
                </div>

                <div class="mb-3">
                  <label
                    for="title"
                    class="text-white w-100"
                    style="text-align: left"
                  >
                    {{ "GENERALS.VALUE" | translate }}
                  </label>
                  <input
                    type="text"
                    id="optValue"
                    #optValue
                    class="form-control input-lg offcanvas-primary border-0"
                    formControlName="value"
                  />
                </div>
                <div class="mb-3" *ngIf="config?.divider">
                  <div class="form-check d-flex align-items-center justify-content-between p-0">
                    <label class="text-white w-100 m-0" for="divider">
                      Divider
                    </label>
                    <input
                      class="form-check-input offcanvas-border-primary offcanvas-primary"
                      type="checkbox"
                      id="divider"
                      #divider
                      style="width: 18px; height: 18px"
                      formControlName="divider"
                    />
                  </div>
                </div>
                <div
                  class="d-flex flex-wrap justify-content-end align-content-center gap-2"
                >
                  <button
                    type="button"
                    class="btn btn-secondary"
                    (click)="openSettings(null)"
                  >
                    Cancel
                  </button>
                  <button type="submit" class="btn btn-primary">Save</button>
                </div>
              </form>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
    <div
      class="row m-0 mb-2"
      *ngIf="
        !selecteSittings &&
        (config?.max == 0 ||
          (config?.max > 0 && config.max > button_option.length))
      "
    >
      <button
        class="btn btn-primary shadow-none col-6"
        type="button"
        (click)="addNewSwaper()"
      >
        Add New Item
      </button>
    </div>
    <div class="row d-flex justify-content-between align-items-center mb-3">
      <div class="col-12">
        <div class="form-check d-flex align-items-center m-0 p-0">
          <label class="text-white w-100 m-0" for="formReply">
            {{ "GENERALS.DEFAULTVALUES" | translate }}
            <i
              class="mdi mdi-information-outline font-size-16 ms-1"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="You can set the default values"
            ></i>
          </label>
        </div>
      </div>
      <div class="col-12">
        <accordion
          [isAnimated]="true"
          class="accordion custom-accordion offcanvas-border-primary offcanvas-primary overflow-hidden"
          style="border-radius: 0.25rem"
        >
          <accordion-group
            heading="{{ 'GENERALS.SETDEFAULTVALUES' | translate }}"
            class="accordion-item offcanvas-border-primary offcanvas-primary overflow-hidden"
            #flush1
            style="border-radius: 0.25rem"
          >
            <span accordion-heading class="float-end fs-5">
              <i
                class="mdi"
                [ngClass]="
                  !flush1?.isOpen
                    ? 'mdi mdi-chevron-down accor-plus-icon'
                    : 'mdi mdi-chevron-up accor-plus-icon'
                "
              ></i>
            </span>
            <div class="simplebar-content-wrapper" style="max-height: 200px;">
              <div class="mb-3" *ngFor="let opt of button_option; let i = index">
                <div class="form-check d-flex align-items-center">
                  <input
                    class="form-check-input offcanvas-border-primary offcanvas-primary me-2"
                    type="checkbox"
                    [id]="'divider' + i"
                    #divider
                    style="width: 18px; height: 18px"
                    (change)="addButtonToValue(opt)"
                    [checked]="getSelectedButtonValue(opt)"
                  />
                  <label class="text-white w-100 m-0" [for]="'divider' + i">
                    {{ opt?.label }}
                  </label>
                </div>
              </div>
            </div>
          </accordion-group>
        </accordion>
      </div>
    </div>
  </div>

  <ng-template #selectorOption let-modal>
    <div class="modal-header">
      <h5 class="modal-title" id="selector">Choose Option Icon</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      ></button>
    </div>
    <div class="modal-body">
      <app-icons-selector
        (selectedIcon)="changeIcon($event)"
      ></app-icons-selector>
    </div>
  </ng-template>
</div>
