import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import {
  ButtonFormTypes,
  Buttons_Styles,
  Choices_Styles,
  Field_Text_Styles,
  TabNames,
  c1,
  offcanvasColors,
} from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Tab } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";

@Component({
  selector: "app-button-style-component",
  templateUrl: "./button-style-component.component.html",
  styleUrls: ["./button-style-component.component.scss"],
})
export class ButtonStyleComponentComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService
  ) {}

  @Input() button_id: string;
  @Input() config: any;
  mode: string;

  // default values
  icon: string;
  offCanvasSubscription: any;
  offcanvasColors = offcanvasColors;
  button;
  data: any;
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  component_id: string;
  field_id: string;
  isAndroid: boolean;
  text_id: any;
  buttonFormTypes: typeof ButtonFormTypes = ButtonFormTypes;
  labelform: UntypedFormGroup;

  stylesChoices = [];

  ngOnInit() {
    this.field_id = "lable";
    this.labelform = this.formBuilder.group({
      style: new UntypedFormControl(""),
      mirror: new UntypedFormControl(false),
      divider: new UntypedFormControl(false),
      cardType: new UntypedFormControl(""),
    });
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );

    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.icon;
          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;
    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
    if (this.button) {
      this.text_id = this.config.id.split(/_(.*)/s)[1];
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;
      const currentStyle = this.isAndroid
        ? this.button.button_style.split("")
        : this.button.button_style_ios.split("");

      switch (this.button.button_form) {
        case this.buttonFormTypes.BUTTON:
          this.stylesChoices = [...Buttons_Styles];
          this.labelform = this.formBuilder.group({
            style: new UntypedFormControl(
              currentStyle[0] + currentStyle[1] || ""
            ),
            mirror: new UntypedFormControl(""),
            divider: new UntypedFormControl(""),
            cardType: new UntypedFormControl(""),
          });
          break;
        case this.buttonFormTypes.INOUT:
          this.stylesChoices = [...Field_Text_Styles];
          this.labelform = this.formBuilder.group({
            style: new UntypedFormControl(
              currentStyle[0] + currentStyle[1] || ""
            ),
            mirror: new UntypedFormControl(""),
            divider: new UntypedFormControl(""),
            cardType: new UntypedFormControl(""),
          });
          break;
        case this.buttonFormTypes.TOGGLE:
        case this.buttonFormTypes.SINGLECHOICE:
        case this.buttonFormTypes.MULTIPLECHOICE:
          this.stylesChoices = [...Choices_Styles];
          this.labelform = this.formBuilder.group({
            style: new UntypedFormControl(currentStyle[0] || ""),
            mirror: new UntypedFormControl(currentStyle[1] !== "0"),
            divider: new UntypedFormControl(currentStyle[2] !== "0"),
            cardType: new UntypedFormControl(currentStyle[3] || ""),
          });
          break;
      }
    }
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );
  }

  changeStyle(key, index) {
    let newStyle = "";
    const currentStyle = this.button.button_style.split("");
    switch (this.button.button_form) {
      case this.buttonFormTypes.INOUT:
      case this.buttonFormTypes.BUTTON:
        const style = this.labelform.value[key].split("");
        currentStyle[0] = style[0];
        currentStyle[1] = style[1];
        newStyle = currentStyle.join().replaceAll(",", "");
        break;
      case this.buttonFormTypes.TOGGLE:
      case this.buttonFormTypes.SINGLECHOICE:
      case this.buttonFormTypes.MULTIPLECHOICE:
        currentStyle[index] = this.labelform.value[key];
        newStyle = currentStyle.join().replaceAll(",", "");
        break;
    }
    this.button.button_style = newStyle;
    this.updateButtonData();
  }

  changeDividerStyle(key, index) {
    let newStyle = "";
    const currentStyle = this.button.button_style.split("");
    currentStyle[index] = Number(this.labelform.value[key]).toString();
    newStyle = currentStyle.join().replaceAll(",", "");
    this.button.button_style = newStyle;
    this.updateButtonData();
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
