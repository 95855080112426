<div class="offcanvase-side">
  <div
    class="offcanvas offcanvas-end navbar-brand-box p-0 z-1"
    style="top: 70px; width: 400px !important; visibility: visible !important"
    data-bs-scroll="true"
    data-bs-backdrop="false"
    tabindex="-1"
    id="offcanvasScrollingButtonConf"
    aria-labelledby="offcanvasScrollingTabsConf"
  >
    <div
      class="offcanvas-header ps-0 mb-0"
      style="border-bottom: 1px solid #383d4b"
    >
      <div id="sidebar-menu" class="p-0">
        <ul class="metismenu list-unstyled">
          <li draggable="false">
            <a class="side-nav-link-ref">
              <!-- <span>Builder Component</span> -->
              <h5>
                {{ title }}
              </h5>
            </a>
          </li>
        </ul>
      </div>
      <button
        type="button"
        class="btn-close btn-close-white text-reset"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
        (click)="hideOffcanvas()"
      ></button>
    </div>
    <div class="offcanvas-body p-0 m-0">
      <ng-container *ngIf="offcanvas_data.length == 1">
        <ng-container *ngFor="let tab of offcanvas_data">
          <div id="sidebar-menu p-0">
            <!-- Left Menu Start -->
            <ul class="metismenu list-unstyled">
              <!-- {{menuItems}} -->
              <ng-container *ngFor="let cat of tab?.data; let i = index">
                <li
                  *ngIf="cat.show"
                  draggable="false"
                  style="background-color: #3f4454 !important"
                >
                  <a class="side-nav-link-ref">
                    <span>{{ cat.cat }}</span>
                  </a>
                </li>

                <div *ngFor="let item of cat?.list; let j = index">
                  <!-- **** componets Start **** -->
                  <div
                    *ngIf="item.button_callback"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-boutton-info-component
                      [button_id]="button_id"
                    ></app-boutton-info-component>
                  </div>

                  <div
                    *ngIf="item.button_label_withoutcolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-label-withoutcolor-component
                      [button_id]="button_id"
                    ></app-button-label-withoutcolor-component>
                  </div>
                  <div
                    *ngIf="item.button_style"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-style-component
                      [button_id]="button_id"
                      [config]="item?.config"
                    ></app-button-style-component>
                  </div>
                  <div
                    *ngIf="item.button_style_with_mirror"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-style-with-mirror-component
                      [button_id]="button_id"
                    ></app-button-style-with-mirror-component>
                  </div>
                  <div
                    *ngIf="item.button_subLable"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-sub-label-component
                      [button_id]="button_id"
                    ></app-button-sub-label-component>
                  </div>
                  <div
                    *ngIf="item.button_optioncolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-color-component
                      [button_id]="button_id"
                    ></app-button-option-color-component>
                  </div>
                  <div
                    *ngIf="item.button_optionRadio"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-radio-component
                      [button_id]="button_id"
                    ></app-button-option-radio-component>
                  </div>
                  <div
                    *ngIf="item.button_optionRadioIcon"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-radio-icon-component
                      [button_id]="button_id"
                    ></app-button-option-radio-icon-component>
                  </div>
                  <div
                    *ngIf="item.button_optionRadioImage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-radio-image-component
                      [button_id]="button_id"
                    ></app-button-option-radio-image-component>
                  </div>
                  <div
                    *ngIf="item.button_optionCheck"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-check-component
                      [button_id]="button_id"
                    ></app-button-option-check-component>
                  </div>
                  <div
                    *ngIf="item.button_optionCheckIcon"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-component
                      [button_id]="button_id"
                      [config]="item?.config"
                    ></app-button-option-component>
                  </div>
                  <div
                    *ngIf="item.button_optionCheckImage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-check-image-component
                      [button_id]="button_id"
                    ></app-button-option-check-image-component>
                  </div>
                  <div
                    *ngIf="item.button_optionSelect"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-select-component
                      [button_id]="button_id"
                    ></app-button-option-select-component>
                  </div>
                  <div
                    *ngIf="item.button_optionSelect_icon"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-option-select-icon-component
                      [button_id]="button_id"
                    ></app-button-option-select-icon-component>
                  </div>
                  <div
                    *ngIf="item.button_icon"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-icon-component
                      [button_id]="button_id"
                    ></app-button-icon-component>
                  </div>
                  <div
                    *ngIf="item.button_border_color"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-border-color-component
                      [button_id]="button_id"
                    ></app-button-border-color-component>
                  </div>
                  <div
                    *ngIf="item.button_textcolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-text-color-component
                      [button_id]="button_id"
                    ></app-button-text-color-component>
                  </div>
                  <div
                    *ngIf="item.button_value"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-value-component
                      [button_id]="button_id"
                    ></app-button-value-component>
                  </div>
                  <div
                    *ngIf="item.button_action"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-action-type-component
                      [button_id]="button_id"
                    ></app-button-action-type-component>
                  </div>
                  <div
                    *ngIf="item.button_access"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-access-component
                      [button_id]="button_id"
                    ></app-button-access-component>
                  </div>
                  <div
                    *ngIf="item.button_newpage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-new-screen-component
                      [button_id]="button_id"
                    ></app-button-new-screen-component>
                  </div>
                  <div
                    *ngIf="item.button_db"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-menu-cache-component
                      [button_id]="button_id"
                    ></app-button-menu-cache-component>
                  </div>
                  <div
                    *ngIf="item.button_keyboard"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-keyboard-component
                      [button_id]="button_id"
                    ></app-button-keyboard-component>
                  </div>
                  <div
                    *ngIf="item.image_uploader"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-image-uploader
                      [button_id]="button_id"
                    ></app-image-uploader>
                  </div>
                  <div
                    *ngIf="item.button_image_uploader"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-image-uploader-component
                      [button_id]="button_id"
                    ></app-button-image-uploader-component>
                  </div>
                  <div
                    *ngIf="item.offcanvas_swaper"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-offcanvas-swaper
                      [button_id]="button_id"
                    ></app-offcanvas-swaper>
                  </div>
                  <div
                    *ngIf="item.pageSocialBar"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-social-bar-component
                      [button_id]="button_id"
                    ></app-button-page-social-bar-component>
                  </div>
                  <div
                    *ngIf="item.pageSocialBarStyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-social-bar-style-component
                      [button_id]="button_id"
                    ></app-button-page-social-bar-style-component>
                  </div>
                  <div
                    *ngIf="item.pageStylePosition"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-style-position-component
                      [button_id]="button_id"
                    ></app-button-page-style-position-component>
                  </div>

                  <div
                    *ngIf="item.pageLocationMarker"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-location-markers-component
                      [button_id]="button_id"
                    ></app-button-page-location-markers-component>
                  </div>
                  <div
                    *ngIf="item.pageLocationCenter"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-location-center-component
                      [button_id]="button_id"
                    ></app-button-page-location-center-component>
                  </div>
                  <div
                    *ngIf="item.pageLocationZoom"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-location-zoom-component
                      [button_id]="button_id"
                    ></app-button-page-location-zoom-component>
                  </div>
                  <div
                    *ngIf="item.pageVideo"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-video-component
                      [button_id]="button_id"
                    ></app-button-page-video-component>
                  </div>
                  <div
                    *ngIf="item.pageVideoStyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-video-style-component
                      [button_id]="button_id"
                    ></app-button-page-video-style-component>
                  </div>
                  <div
                    *ngIf="item.pageQr"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-qr-component
                      [button_id]="button_id"
                    ></app-button-page-qr-component>
                  </div>
                  <div
                    *ngIf="item.pageMultiImage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-multi-image-uploader-component
                      [button_id]="button_id"
                    ></app-button-page-multi-image-uploader-component>
                  </div>
                  <div
                    *ngIf="item.pageSwaperiImage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-swaper-image-uploader-component
                      [button_id]="button_id"
                    ></app-button-page-swaper-image-uploader-component>
                  </div>
                  <div
                    *ngIf="item.pageSlideAnimation"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-slider-animation-component
                      [button_id]="button_id"
                    ></app-button-page-slider-animation-component>
                  </div>
                  <div
                    *ngIf="item.pageSlideStyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-slider-style-component
                      [button_id]="button_id"
                    ></app-button-page-slider-style-component>
                  </div>

                  <div
                    *ngIf="item.pageGalleryStyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-gallery-style-component
                      [button_id]="button_id"
                    ></app-button-page-gallery-style-component>
                  </div>
                  <div
                    *ngIf="item.pageSingleImageUploader"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-single-image-uploader-component
                      [button_id]="button_id"
                    ></app-button-page-single-image-uploader-component>
                  </div>
                  <div
                    *ngIf="item.pageSingleImageStyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-single-image-style-component
                      [button_id]="button_id"
                    ></app-button-page-single-image-style-component>
                  </div>
                  <div
                    *ngIf="item.pageHeader"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-header-component
                      [button_id]="button_id"
                    ></app-button-page-header-component>
                  </div>
                  <div
                    *ngIf="item.pageBgColors"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-bg-colors-component
                      [button_id]="button_id"
                    ></app-button-page-bg-colors-component>
                  </div>
                  <div
                    *ngIf="item.pageHeaderImage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-header-image-uploader-component
                      [button_id]="button_id"
                    ></app-button-page-header-image-uploader-component>
                  </div>
                  <div
                    *ngIf="item.pageInterval"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-interval-component
                      [button_id]="button_id"
                    ></app-button-page-interval-component>
                  </div>
                  <div
                    *ngIf="item.pageBorderColor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-border-color-component
                      [button_id]="button_id"
                    ></app-button-page-border-color-component>
                  </div>
                  <div
                    *ngIf="item.pageBorderMt"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-border-mt-component
                      [button_id]="button_id"
                    ></app-button-page-border-mt-component>
                  </div>

                  <div
                    *ngIf="item.pageBorderMb"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-border-mb-component
                      [button_id]="button_id"
                    ></app-button-page-border-mb-component>
                  </div>
                  <div
                    *ngIf="item.pageBorderMsMe"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-border-ms-component
                      [button_id]="button_id"
                    ></app-button-page-border-ms-component>
                  </div>
                  <div
                    *ngIf="item.pageBorderSize"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-border-size-component
                      [button_id]="button_id"
                    ></app-button-page-border-size-component>
                  </div>
                  <div
                    *ngIf="item.pageBorderStyle"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-page-border-style-component
                      [button_id]="button_id"
                    ></app-button-page-border-style-component>
                  </div>

                  <!-- **** Added by Hazem **** -->
                  <div
                    *ngIf="item.buttonlabelhtml"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-label-html
                      [button_id]="button_id"
                    ></app-button-label-html>
                  </div>

                  <div
                    *ngIf="item.buttonsplashlabelhtml"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-splashlabel-html
                      [button_id]="button_id"
                      [tabData]="current_tab"
                    ></app-button-splashlabel-html>
                  </div>

                  <div
                    *ngIf="item.buttonsublabelhtml"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-sublabel-html
                      [button_id]="button_id"
                    ></app-button-sublabel-html>
                  </div>
                  <div
                    *ngIf="item.buttonimage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-image
                      [button_id]="button_id"
                    ></app-button-image>
                  </div>

                  <div
                    *ngIf="item.buttonsplashimage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-splashimage
                      [button_id]="button_id"
                      [tabData]="current_tab"
                    ></app-button-splashimage>
                  </div>

                  <div
                    *ngIf="item.buttonbgcolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-bgcolor
                      [button_id]="button_id"
                    ></app-button-bgcolor>
                  </div>

                  <div
                    *ngIf="item.buttonsplashbgcolor"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-splashbgcolor
                      [button_id]="button_id"
                      [tabData]="current_tab"
                    ></app-button-splashbgcolor>
                  </div>

                  <div
                    *ngIf="item.btabsplashtnc"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-tabsplash-tnc
                      [button_id]="button_id"
                      [tabData]="(current_tab[config] = item?.config)"
                    ></app-button-tabsplash-tnc>
                  </div>

                  <div
                    *ngIf="item.btabsplashbutton"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-tabsplash-button
                      [button_id]="button_id"
                      [tabData]="current_tab"
                    ></app-button-tabsplash-button>
                  </div>

                  <div
                    *ngIf="item.btabsplashtitlehtml"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-tabsplash-titlehtml
                      [button_id]="button_id"
                      [tabData]="current_tab"
                    ></app-button-tabsplash-titlehtml>
                  </div>

                  <div
                    *ngIf="item.buttonsplashbgimage"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-splashbgimage
                      [button_id]="button_id"
                      [tabData]="current_tab"
                    ></app-button-splashbgimage>
                  </div>
                  <div
                    *ngIf="item.button_label"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-label-component
                      [button_id]="button_id"
                      [config]="item?.config"
                    ></app-button-label-component>
                  </div>
                  <div
                    *ngIf="item.button_main"
                    [ngClass]="
                      i == 0 && j == 0
                        ? i == tab.data.length - 1 && j == cat.list.length - 1
                          ? ''
                          : 'border-bottom-3d'
                        : i == tab.data.length - 1 && j == cat.list.length - 1
                        ? 'border-top-3d'
                        : 'border-3d'
                    "
                  >
                    <app-button-main-component
                      [button_id]="button_id"
                      [config]="item?.config"
                    ></app-button-main-component>
                  </div>

                  <!-- **** componets Ends  **** -->
                </div>
              </ng-container>
            </ul>
          </div>
        </ng-container>
      </ng-container>

      <tabset
        [justified]="true"
        class="nav-tabs nav-tabs-custom nav-justified tabPrimaryFilled"
        *ngIf="offcanvas_data.length > 1"
      >
        <ng-container *ngFor="let tab of offcanvas_data">
          <tab
            [heading]="tab?.tab"
            id="{{ tab?.id }}"
            [active]="tab?.active ? tab?.active : 0"
            (selectTab)="tabSetChange($event)"
            class="pb-2"
          >
            <div id="sidebar-menu p-0">
              <!-- Left Menu Start -->
              <ul class="metismenu list-unstyled p-0">
                <!-- {{menuItems}} -->
                <ng-container *ngFor="let cat of tab?.data">
                  <li
                    *ngIf="cat.show"
                    draggable="false"
                    style="background-color: #3f4454 !important"
                  >
                    <a class="side-nav-link-ref">
                      <span>{{ cat.cat }}</span>
                    </a>
                  </li>
                  <div *ngFor="let item of cat?.list; let j = index">
                    <!-- **** componets Start **** -->
                    <div
                      *ngIf="item.button_callback"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-boutton-info-component
                        [button_id]="button_id"
                      ></app-boutton-info-component>
                    </div>

                    <div
                      *ngIf="item.button_label_withoutcolor"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-label-withoutcolor-component
                        [button_id]="button_id"
                      ></app-button-label-withoutcolor-component>
                    </div>
                    <div
                      *ngIf="item.button_style"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-style-component
                        [button_id]="button_id"
                        [config]="item?.config"
                      ></app-button-style-component>
                    </div>
                    <div
                      *ngIf="item.button_style_with_mirror"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-style-with-mirror-component
                        [button_id]="button_id"
                      ></app-button-style-with-mirror-component>
                    </div>
                    <div
                      *ngIf="item.button_subLable"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-sub-label-component
                        [button_id]="button_id"
                      ></app-button-sub-label-component>
                    </div>
                    <div
                      *ngIf="item.button_bgcolor"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-bg-color-component
                        [button_id]="button_id"
                      ></app-button-bg-color-component>
                    </div>
                    <div
                      *ngIf="item.button_optioncolor"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-color-component
                        [button_id]="button_id"
                      ></app-button-option-color-component>
                    </div>
                    <div
                      *ngIf="item.button_optionRadio"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-radio-component
                        [button_id]="button_id"
                      ></app-button-option-radio-component>
                    </div>
                    <div
                      *ngIf="item.button_optionRadioIcon"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-radio-icon-component
                        [button_id]="button_id"
                      ></app-button-option-radio-icon-component>
                    </div>
                    <div
                      *ngIf="item.button_optionRadioImage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-radio-image-component
                        [button_id]="button_id"
                      ></app-button-option-radio-image-component>
                    </div>
                    <div
                      *ngIf="item.button_optionCheck"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-check-component
                        [button_id]="button_id"
                      ></app-button-option-check-component>
                    </div>
                    <div
                      *ngIf="item.button_optionCheckIcon"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-component
                        [button_id]="button_id"
                        [config]="item?.config"
                      ></app-button-option-component>
                    </div>
                    <div
                      *ngIf="item.button_optionCheckImage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-check-image-component
                        [button_id]="button_id"
                      ></app-button-option-check-image-component>
                    </div>
                    <div
                      *ngIf="item.button_optionSelect"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-select-component
                        [button_id]="button_id"
                      ></app-button-option-select-component>
                    </div>
                    <div
                      *ngIf="item.button_optionSelect_icon"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-option-select-icon-component
                        [button_id]="button_id"
                      ></app-button-option-select-icon-component>
                    </div>
                    <div
                      *ngIf="item.button_icon"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-icon-component
                        [button_id]="button_id"
                      ></app-button-icon-component>
                    </div>
                    <div
                      *ngIf="item.button_border_color"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-border-color-component
                        [button_id]="button_id"
                      ></app-button-border-color-component>
                    </div>
                    <div
                      *ngIf="item.button_textcolor"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-text-color-component
                        [button_id]="button_id"
                      ></app-button-text-color-component>
                    </div>
                    <div
                      *ngIf="item.button_value"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-value-component
                        [button_id]="button_id"
                      ></app-button-value-component>
                    </div>
                    <div
                      *ngIf="item.button_action"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-action-type-component
                        [button_id]="button_id"
                      ></app-button-action-type-component>
                    </div>
                    <div
                      *ngIf="item.button_access"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-access-component
                        [button_id]="button_id"
                      ></app-button-access-component>
                    </div>
                    <div
                      *ngIf="item.button_newpage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-new-screen-component
                        [button_id]="button_id"
                      ></app-button-new-screen-component>
                    </div>
                    <div
                      *ngIf="item.button_db"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-menu-cache-component
                        [button_id]="button_id"
                      ></app-button-menu-cache-component>
                    </div>
                    <div
                      *ngIf="item.button_keyboard"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-keyboard-component
                        [button_id]="button_id"
                      ></app-button-keyboard-component>
                    </div>
                    <div
                      *ngIf="item.image_uploader"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-image-uploader
                        [button_id]="button_id"
                      ></app-image-uploader>
                    </div>
                    <div
                      *ngIf="item.button_image_uploader"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-image-uploader-component
                        [button_id]="button_id"
                      ></app-button-image-uploader-component>
                    </div>
                    <div
                      *ngIf="item.offcanvas_swaper"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-offcanvas-swaper
                        [button_id]="button_id"
                      ></app-offcanvas-swaper>
                    </div>
                    <div
                      *ngIf="item.pageSocialBar"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-social-bar-component
                        [button_id]="button_id"
                      ></app-button-page-social-bar-component>
                    </div>
                    <div
                      *ngIf="item.pageSocialBarStyle"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-social-bar-style-component
                        [button_id]="button_id"
                      ></app-button-page-social-bar-style-component>
                    </div>
                    <div
                      *ngIf="item.pageStylePosition"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-style-position-component
                        [button_id]="button_id"
                      ></app-button-page-style-position-component>
                    </div>

                    <div
                      *ngIf="item.pageLocationMarker"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-location-markers-component
                        [button_id]="button_id"
                      ></app-button-page-location-markers-component>
                    </div>
                    <div
                      *ngIf="item.pageLocationCenter"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-location-center-component
                        [button_id]="button_id"
                      ></app-button-page-location-center-component>
                    </div>
                    <div
                      *ngIf="item.pageLocationZoom"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-location-zoom-component
                        [button_id]="button_id"
                      ></app-button-page-location-zoom-component>
                    </div>
                    <div
                      *ngIf="item.pageVideo"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-video-component
                        [button_id]="button_id"
                      ></app-button-page-video-component>
                    </div>
                    <div
                      *ngIf="item.pageVideoStyle"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-video-style-component
                        [button_id]="button_id"
                      ></app-button-page-video-style-component>
                    </div>
                    <div
                      *ngIf="item.pageQr"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-qr-component
                        [button_id]="button_id"
                      ></app-button-page-qr-component>
                    </div>
                    <div
                      *ngIf="item.pageMultiImage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-multi-image-uploader-component
                        [button_id]="button_id"
                      ></app-button-page-multi-image-uploader-component>
                    </div>
                    <div
                      *ngIf="item.pageSwaperiImage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-swaper-image-uploader-component
                        [button_id]="button_id"
                      ></app-button-page-swaper-image-uploader-component>
                    </div>
                    <div
                      *ngIf="item.pageSlideAnimation"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-slider-animation-component
                        [button_id]="button_id"
                      ></app-button-page-slider-animation-component>
                    </div>
                    <div
                      *ngIf="item.pageSlideStyle"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-slider-style-component
                        [button_id]="button_id"
                      ></app-button-page-slider-style-component>
                    </div>

                    <div
                      *ngIf="item.pageGalleryStyle"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-gallery-style-component
                        [button_id]="button_id"
                      ></app-button-page-gallery-style-component>
                    </div>
                    <div
                      *ngIf="item.pageSingleImageUploader"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-single-image-uploader-component
                        [button_id]="button_id"
                      ></app-button-page-single-image-uploader-component>
                    </div>
                    <div
                      *ngIf="item.pageSingleImageStyle"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-single-image-style-component
                        [button_id]="button_id"
                      ></app-button-page-single-image-style-component>
                    </div>
                    <div
                      *ngIf="item.pageHeader"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-header-component
                        [button_id]="button_id"
                      ></app-button-page-header-component>
                    </div>
                    <div
                      *ngIf="item.pageBgColors"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-bg-colors-component
                        [button_id]="button_id"
                      ></app-button-page-bg-colors-component>
                    </div>
                    <div
                      *ngIf="item.pageHeaderImage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-header-image-uploader-component
                        [button_id]="button_id"
                      ></app-button-page-header-image-uploader-component>
                    </div>
                    <div
                      *ngIf="item.pageInterval"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-interval-component
                        [button_id]="button_id"
                      ></app-button-page-interval-component>
                    </div>
                    <div
                      *ngIf="item.pageBorderColor"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-border-color-component
                        [button_id]="button_id"
                      ></app-button-page-border-color-component>
                    </div>
                    <div
                      *ngIf="item.pageBorderMt"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-border-mt-component
                        [button_id]="button_id"
                      ></app-button-page-border-mt-component>
                    </div>

                    <div
                      *ngIf="item.pageBorderMb"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-border-mb-component
                        [button_id]="button_id"
                      ></app-button-page-border-mb-component>
                    </div>
                    <div
                      *ngIf="item.pageBorderMsMe"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-border-ms-component
                        [button_id]="button_id"
                      ></app-button-page-border-ms-component>
                    </div>
                    <div
                      *ngIf="item.pageBorderSize"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-border-size-component
                        [button_id]="button_id"
                      ></app-button-page-border-size-component>
                    </div>
                    <div
                      *ngIf="item.pageBorderStyle"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-page-border-style-component
                        [button_id]="button_id"
                      ></app-button-page-border-style-component>
                    </div>

                    <!-- ** added by Hazem ** -->
                    <div
                      *ngIf="item.buttonlabelhtml"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-label-html
                        [button_id]="button_id"
                      ></app-button-label-html>
                    </div>

                    <div
                      *ngIf="item.buttonsplashlabelhtml"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-splashlabel-html
                        [button_id]="button_id"
                        [tabData]="current_tab"
                      ></app-button-splashlabel-html>
                    </div>

                    <div
                      *ngIf="item.buttonsublabelhtml"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-sublabel-html
                        [button_id]="button_id"
                      ></app-button-sublabel-html>
                    </div>

                    <div
                      *ngIf="item.buttonimage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-image
                        [button_id]="button_id"
                      ></app-button-image>
                    </div>

                    <div
                      *ngIf="item.buttonsplashimage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-splashimage
                        [button_id]="button_id"
                        [tabData]="current_tab"
                      ></app-button-splashimage>
                    </div>

                    <div
                      *ngIf="item.buttonbgcolor"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-bgcolor
                        [button_id]="button_id"
                      ></app-button-bgcolor>
                    </div>

                    <div
                      *ngIf="item.buttonsplashbgcolor"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-splashbgcolor
                        [button_id]="button_id"
                        [tabData]="current_tab"
                      ></app-button-splashbgcolor>
                    </div>

                    <div
                      *ngIf="item.btabsplashtnc"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-tabsplash-tnc
                        [button_id]="button_id"
                        [tabData]="current_tab"
                        [config]="item?.config"
                      ></app-button-tabsplash-tnc>
                    </div>

                    <div
                      *ngIf="item.btabsplashbutton"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-tabsplash-button
                        [button_id]="button_id"
                        [tabData]="current_tab"
                      ></app-button-tabsplash-button>
                    </div>

                    <div
                      *ngIf="item.btabsplashtitlehtml"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-tabsplash-titlehtml
                        [button_id]="button_id"
                        [tabData]="current_tab"
                      ></app-button-tabsplash-titlehtml>
                    </div>

                    <div
                      *ngIf="item.buttonsplashbgimage"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-splashbgimage
                        [button_id]="button_id"
                        [tabData]="current_tab"
                      ></app-button-splashbgimage>
                    </div>

                    <div
                      *ngIf="item.button_label"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-label-component
                        [button_id]="button_id"
                        [config]="item?.config"
                      ></app-button-label-component>
                    </div>
                    <div
                      *ngIf="item.button_main"
                      [ngClass]="
                        i == 0 && j == 0
                          ? i == tab.data.length - 1 && j == cat.list.length - 1
                            ? ''
                            : 'border-bottom-3d'
                          : i == tab.data.length - 1 && j == cat.list.length - 1
                          ? 'border-top-3d'
                          : 'border-3d'
                      "
                    >
                      <app-button-main-component
                        [button_id]="button_id"
                        [config]="item?.config"
                      ></app-button-main-component>
                    </div>

                    <!-- **** componets Ends  **** -->
                  </div>
                </ng-container>
              </ul>
            </div>
          </tab>
        </ng-container>
      </tabset>
    </div>
  </div>
</div>
