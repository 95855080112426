<div class="card m-0 bg-transparent">
  <div class="card-body">
    <form [formGroup]="labelform">
      <div class="row d-flex justify-content-between align-items-center mb-3">
        <div class="col-12 m-0">
          <label
            for="text-color"
            class="text-white w-100"
            style="text-align: left"
          >
            Style
          </label>
          <select
            id="selectoption"
            class="form-select offcanvas-primary ng-pristine ng-valid ng-touched"
            formControlName="style"
            (change)="changeStyle('style', 0)"
          >
            <option *ngFor="let item of stylesChoices" [value]="item?.value">
              {{ isAndroid ? item?.label : item?.label_ios }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="row d-flex justify-content-between align-items-center mb-3"
        *ngIf="config?.mirror"
      >
        <div
          class="form-check d-flex align-items-center justify-content-between"
        >
          <label for="mirror" class="text-white m-0">
            {{ "GENERALS.MIRROR" | translate }}
          </label>
          <input
            type="checkbox"
            id="mirror"
            class="form-check-input offcanvas-border-primary offcanvas-primary"
            style="width: 18px; height: 18px"
            formControlName="mirror"
            (change)="changeDividerStyle('mirror', 1)"
          />
        </div>
      </div>
      <div
        class="row d-flex justify-content-between align-items-center mb-3"
        *ngIf="config?.divider"
      >
        <div
          class="form-check d-flex align-items-center justify-content-between"
        >
          <label for="divider" class="text-white m-0">
            {{ "GENERALS.DIVIDER" | translate }}
          </label>
          <input
            type="checkbox"
            id="divider"
            class="form-check-input offcanvas-border-primary offcanvas-primary"
            style="width: 18px; height: 18px"
            formControlName="divider"
            (change)="changeDividerStyle('divider', 2)"
          />
        </div>
      </div>
      <div
        *ngIf="config?.card"
        class="row d-flex justify-content-between align-items-center mb-3"
      >
        <div class="col-12 m-0">
          <label
            for="card-type"
            class="text-white w-100"
            style="text-align: left"
          >
            Card Type
          </label>
          <select
            id="card-type"
            class="form-select offcanvas-primary ng-pristine ng-valid ng-touched"
            formControlName="cardType"
            (change)="changeStyle('cardType', 3)"
          >
            <option value="0">elevated card</option>
            <option value="1">filled card</option>
            <option value="2">outlined card</option>
          </select>
        </div>
      </div>
    </form>
  </div>
</div>
