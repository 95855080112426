import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { c1, offcanvasColors } from "src/app/core/services/constants";
import { IndexDBService } from "src/app/core/services/indexDB.service";
import { Media, MenuButton } from "src/app/core/services/interface";
import { MiddlwareService } from "src/app/core/services/middleware.service";
import { WebsocketService } from "src/app/core/services/websocket.service";
import { BuilderService } from "src/app/sharedservices/builder.service";

@Component({
  selector: "app-button-option-component",
  templateUrl: "./button-option-component.component.html",
  styleUrls: ["./button-option-component.component.scss"],
})
export class ButtonOptionComponentComponent implements OnInit, OnDestroy {
  constructor(
    private ws: WebsocketService,
    private mdw: MiddlwareService,
    public indexDBService: IndexDBService,
    private modalService: BsModalService,
    private builderService: BuilderService,
    private formBuilder: UntypedFormBuilder
  ) {}

  @Input() button_id: string;
  button: MenuButton;
  mode: string;
  @Input() data: any;
  @Input() config: any;
  @ViewChild("largeDataModal") myModal;

  verifySubscription: any;
  selectedImages: any[] = [];
  swaperForm: UntypedFormGroup;
  submitted = false;
  collapseMedia = true;
  modalRef?: BsModalRef;
  file: any;
  aspectRatio;
  resizeToWidth = 0;
  resizeToHeight = 0;
  orgFile: any;
  uploading = false;

  component_id: string;

  selecteSittings: any;
  // default values
  offCanvasSubscription: any;

  offcanvasColors = offcanvasColors;
  newTab = false;
  button_bgcolor: string;
  row_id: string;
  button_option_color = "#ffffff";
  button_option = [];
  button_value = [];
  currentMenuSubscription: any;
  sortedButtonList: any[];
  buttonList: any;
  off_canvas_key: string = "button";
  selectedIcon = {};
  selected_button_value = {};
  isAndroid: boolean;
  field_id: string;
  iconType: number;

  icon: any;
  trailing_icon: any;

  text_id: any;

  imageUploaderConfig: any = {
    id: "swaper",
    title: "Image",
    max: 1,
    selectedImages: [],
    cropperView: false,
    resizeToWidth: 370,
    resizeToHeight: 370,
    removeButton: true,
    removePadding: true,
    aspectRatio: "1x1",
    fullImage: true,
    mode: ["upload", "gallery"],
  };

  optionform: UntypedFormGroup;

  ngOnInit() {
    this.field_id = "lable";
    this.offCanvasSubscription = this.mdw.offCanvasContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.button &&
          data.button.button_id == this.button_id
        ) {
          this.buttonIntialize(data);
          this.buttonFunction();
        }
      }
    );
    this.currentMenuSubscription = this.mdw.currentMenuContainer$.subscribe(
      (data) => {
        if (
          data &&
          data.off_canvas_key === "button" &&
          data.currentButton &&
          this.button_id &&
          data.currentButton.button_id == this.button_id &&
          data["component_id"] != this.component_id
        ) {
          this.data = data;
          this.sortedButtonList = this.data["sortedButtonList"];
          this.buttonList = this.data["buttonList"];
          this.button = data["buttonList"][this.button_id];
          this.icon;
          this.buttonFunction();
        }
      }
    );
  }

  buttonIntialize(data) {
    this.data = data.data;

    if (data.button.button_id == this.button_id) {
      this.button = { ...data.button };
      this.component_id = this.button.button_id + this.field_id;
    }
    this.sortedButtonList = data.data["sortedButtonList"];
    this.buttonList = this.data["buttonList"];
    this.mode = localStorage.getItem("mode");
  }

  buttonFunction() {
    if (this.button) {
      this.text_id = this.config.id.split(/_(.*)/s)[1];
      this.isAndroid =
        localStorage.getItem("mode")[0] === c1.ANDRIOD ? true : false;

      if (this.button.button_option) {
        this.button_option = structuredClone(this.button.button_option);
        // this.selectedImages = this.button_option;
      }

      if (this.button.button_value) {
        this.button_value = this.button.button_value;
        for (let i = 0; i < this.button.button_value.length; i++) {
          this.selected_button_value[this.button.button_value[i].id] =
            this.button.button_value[i];
        }
      }
    }
  }

  updateButtonData() {
    //*must be there in all buttons
    this.buttonList[this.button_id] = this.button;
    let index = this.sortedButtonList[this.button.row_id].findIndex(
      (e) => e.button_id == this.button_id
    );
    this.sortedButtonList[this.button.row_id][index] = { ...this.button };
    this.data["sortedButtonList"] = this.sortedButtonList;
    this.data["buttonList"] = this.buttonList;
    this.data["off_canvas_key"] = this.off_canvas_key;
    this.data["currentButton"] = this.button;

    this.mdw._currentMenuContainer.next(this.data);

    this.mdw.changeTab(
      { button: this.button, tab: { module: "menu" } },
      this.mode,
      true
    );
  }

  addNewSwaper() {
    const swaper: any = {
      id: "o-" + this.mdw.makeRef(16),
      label: "Option" + (this.button_option.length + 1),
      sublabel: "",
      value: "Option" + (this.button_option.length + 1),
      order: this.button_option.length,
    };
    this.button_option.push(swaper);
    this.openSettings(swaper);
  }

  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(
      this.button_option,
      event.previousIndex,
      event.currentIndex
    );
    this.button_option = this.button_option.map((image, index) => {
      image.order = index;
      return image;
    });
    this.button.button_option = this.button_option;
    this.updateButtonData();
  }

  deleteImage(id: string) {
    this.button_option = this.button_option.filter((image) => image.id !== id);
    this.button.button_option = this.button_option;
    this.updateButtonData();
  }

  openSettings(option) {
    this.icon = null;
    this.trailing_icon = null;

    this.optionform = this.formBuilder.group({
      id: new UntypedFormControl((option && option.id) || ""),
      label: new UntypedFormControl((option && option.label) || ""),
      value: new UntypedFormControl((option && option.value) || ""),
      icon: new UntypedFormControl((option && option.icon) || ""),
      icon_ios: new UntypedFormControl((option && option.icon_ios) || ""),
      trailing_icon: new UntypedFormControl(
        (option && option.trailing_icon) || ""
      ),
      trailing_icon_ios: new UntypedFormControl(
        (option && option.trailing_icon_ios) || ""
      ),
      image: new UntypedFormControl((option && option.image) || ""),
      image_ios: new UntypedFormControl((option && option.image_ios) || ""),
      divider: new UntypedFormControl((option && option.divider) || false),
    });

    this.selecteSittings = structuredClone(option);

    if (!this.config.divider) {
      this.optionform.get("divider").disable();
    }

    if (!this.config.icon) {
      this.optionform.get("icon").disable();
      this.optionform.get("icon_ios").disable();
    }

    if (!this.config.trailing_icon) {
      this.optionform.get("trailing_icon").disable();
      this.optionform.get("trailing_icon_ios").disable();
    }

    if (!this.config.image) {
      this.optionform.get("image").disable();
      this.optionform.get("image_ios").disable();
    }

    if (
      this.config.icon &&
      this.optionform.get("icon").value &&
      this.isAndroid
    ) {
      this.icon = this.builderService.getIcon(
        this.optionform.get("icon").value
      );
    }

    if (
      this.config.trailing_icon &&
      this.optionform.get("trailing_icon").value &&
      this.isAndroid
    ) {
      this.trailing_icon = this.builderService.getIcon(
        this.optionform.get("trailing_icon").value
      );
    }

    if (
      this.config.icon &&
      this.optionform.get("icon_ios").value &&
      !this.isAndroid
    ) {
      this.icon = this.builderService.getIcon(
        this.optionform.get("icon_ios").value
      );
    }

    if (
      this.config.trailing_icon &&
      this.optionform.get("trailing_icon_ios").value &&
      !this.isAndroid
    ) {
      this.trailing_icon = this.builderService.getIcon(
        this.optionform.get("trailing_icon_ios").value
      );
    }

    if (this.selecteSittings && this.config.image) {
      const media= {
        id: this.selecteSittings.id,
        media: {
          permanentUrl: this.isAndroid ? this.selecteSittings.image : this.selecteSittings.image_ios,
        }
      };
      this.imageUploaderConfig = {
        ...this.imageUploaderConfig,
        selectedImages: media.media.permanentUrl  ? [media] : [],
      };
    }
  }

  saveChanges() {
    this.selecteSittings = {
      ...this.optionform.value,
    };
    this.button_option = this.button_option.map((image) =>
      image.id === this.selecteSittings.id ? this.selecteSittings : image
    );
    this.button.button_option = this.button_option;
    this.updateButtonData();
    this.openSettings(null);
  }

  openSelector(exlargeModal: any, type) {
    this.iconType = type;
    this.modalRef = this.modalService.show(exlargeModal, { class: "modal-xl" });
  }

  changeIcon(event) {
    if (this.iconType) {
      this.trailing_icon = this.builderService.getIcon(event);
      if (this.isAndroid) {
        this.optionform.patchValue({ trailing_icon: event });
      } else {
        this.optionform.patchValue({ trailing_icon_ios: event });
      }
    } else {
      this.icon = this.builderService.getIcon(event);
      if (this.isAndroid) {
        this.optionform.patchValue({ icon: event });
      } else {
        this.optionform.patchValue({ icon_ios: event });
      }
    }
    this.modalRef.hide();
  }

  addButtonToValue(opt) {
    if (this.selected_button_value[opt.id]) {
      delete this.selected_button_value[opt.id];
    } else {
      this.selected_button_value[opt.id] = opt;
    }

    this.button.button_value = Object.values(this.selected_button_value);
    this.updateButtonData();
  }

  getSelectedButtonValue(opt) {
    return this.selected_button_value[opt.id] ? true : false;
  }

  saveImages(imges: any[]) {
    if (this.isAndroid) {
      this.optionform.patchValue({
        image: imges && imges.length > 0 ? imges[0].media.permanentUrl : '',
      });
    } else {
      this.optionform.patchValue({
        image_ios: imges && imges.length > 0 ? imges[0].media.permanentUrl : '',
      });
    }
  }

  getIcon(opt) {
    if (this.isAndroid) {
      return this.builderService.getIcon(opt.icon);
    } else {
      return this.builderService.getIcon(opt.icon_ios);
    }
  }

  ngOnDestroy(): void {
    if (this.offCanvasSubscription) {
      this.offCanvasSubscription.unsubscribe();
    }
    if (this.currentMenuSubscription) {
      this.currentMenuSubscription.unsubscribe();
    }
  }
}
